import React from "react";
import { SanityImage } from "@components/sanity/sanityImage";
import { ModuleWrapper } from "../moduleWrapper";
import { Link } from "gatsby";

const StandardImage = ({
  config,
  title,
  featuredImage,
  optionalLinkText,
  optionalLink,
}) => {
  return (
    <ModuleWrapper {...config}>
      <h2 className="mb-[50px] lg:mb-[100px] text-center text-5xl text-red">
        {title}
      </h2>
      <div className="px-gutter w-full h-full">
        <SanityImage image={featuredImage} />
      </div>
      <div className="mt-[50px] lg:mt-[100px] flex w-full justify-center">
        <Link className="btn-solid-red !px-10 " to={optionalLink}>
          {optionalLinkText}
        </Link>
      </div>
    </ModuleWrapper>
  );
};

export default StandardImage;
